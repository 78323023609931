import { useContext, useEffect, useRef, useState } from "react";
import AuthLayout from '../components/PublicLayout/authLayout';
import { useNavigate, Link, NavLink } from "react-router-dom";
import {
  Col,
  Button,
  Input,
  FormGroup,
  CardBody,
  InputGroup,
  InputGroupAddon,
  Form,
  Nav,
  NavItem,
  TabContent,
  TabPane
} from "reactstrap";
import APIServicenew from "../utils/APIGeneralService";
import { UserContext } from "../context/UserContextProvider";
import "react-toastify/dist/ReactToastify.css";
import "react-datepicker/dist/react-datepicker.css";
import { ToastContainer, toast } from "react-toastify";
import DatePicker from "react-datepicker";
import Select from 'react-select'


const Profile = (props) => {
  const navigate = useNavigate();
  const APIServices = new APIServicenew(process.env.REACT_APP_OTHER_API_URL);
  const authToken = process.env.REACT_APP_authToken;
  const { accessToken, logout } = useContext(UserContext);
  const headers = { "auth-token": authToken, authorization: accessToken };
  const [data, setData] = useState([]);
  const [mobileNumber, setMobileNumber] = useState(null);
  const [backupMobileNumber, setBackupMobileNumber] = useState(null);
  const [email, setEmail] = useState(null);
  const [firstName, setFirstName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [countryCode, setCountryCode] = useState(null);
  const [backupCountryCode, setBackupCountryCode] = useState(null);
  const { setAccessToken } = useContext(UserContext);
  const [errors, setErrors] = useState({});
  const [selectedDate, setSelectedDate] = useState(null);
  const today = new Date().toISOString().split("T")[0];
  const [showPassword, setShowPassword] = useState(false);
  const [additionalName, setAdditionalName] = useState(null);
  const [additionalSurname, setAdditionalSurname] = useState(null);
  const [additionalAddress, setAdditionalAddress] = useState(null);
  const [questions, setQuestion] = useState([]);
  const [questionSelected, setSelectedQuestion] = useState(null);
  const [answer, setAnswer] = useState();
  const [activeTab, setActiveTab] = useState("0");
  const [lastLogin, setLastLogin] = useState();
  const [load, setLoad] = useState(false);
  const [load1, setLoad1] = useState(false);
  const [load2, setLoad2] = useState(false);
  const [load3, setLoad3] = useState(false);
  const [load4, setLoad4] = useState(false);
  const [load5, setLoad5] = useState(false);

  const [scheduleDate, setScheduleDate] = useState();
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showOldPassword, setShowOldPassword] = useState(false);  // State for password visibility
  const [showNewPassword, setShowNewPassword] = useState(false);  // State for password visibility
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);  // State for confirm password visibility

  const [editGenralInfo, setEditGenralInfo] = useState(false);
  const [editContactInfo, setEditContactInfo] = useState(false);
  const [editAdditionalInfo, setEditAdditionalInfo] = useState(false);
  const [editQuestionInfo, setEditQuestionInfo] = useState(false);
  const [editSafetyInfo, setEditSafetyInfo] = useState(false);
  const [pageLoader, setPageLoader] = useState(true);


  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  useEffect(() => {
    getProfile(true);
    getContact(true);
    getAdditionalInfo(true);
    getControlQuestion(true);
    getControlAnswer(true);
  }, []);

  //get profile details
  const getProfile = async (event) => {
    try {
      setPageLoader(event)
      //call get profile API
      const response = await APIServices.get(
        `getProfile`,
        headers
      );
      if (response?.status == 200) {
        const resp = response?.data?.detail || [];
        //UPDATE ALL STATE FOR SHOW DATA
        const birthday = new Date(resp?.birthday);
        const defaultOption1 = countryOptions.find(option => option.value === resp?.phonenumberprefix);
        const defaultOption2 = countryOptions.find(option => option.value === resp?.backup_prefix);
        setData(resp);
        setEmail(resp?.email);
        setFirstName(resp?.first_name);
        setLastName(resp?.last_name);
        setScheduleDate(birthday);
        setEmail(resp?.email);
        setMobileNumber(resp?.phonenumber);
        setCountryCode(defaultOption1);
        setBackupCountryCode(defaultOption2);
        setBackupMobileNumber(resp?.backup_phone_number);
        let dateStr = resp?.last_login ?? "";
        let formattedDate = "";
        if (dateStr) {
          let date = new Date(dateStr);
          let day = String(date.getDate()).padStart(2, '0');
          let month = String(date.getMonth() + 1).padStart(2, '0'); // Month is 0-based, so add 1
          let year = date.getFullYear();
          // Get hours and minutes
          let hours = String(date.getHours()).padStart(2, '0');
          let minutes = String(date.getMinutes()).padStart(2, '0');
          // Format the full date and time
          formattedDate = `${day}/${month}/${year} ${hours}:${minutes}`;
        }
        setLastLogin(formattedDate);
        setTimeout(() => {
          setPageLoader(false)
        }, 500);
        setEditGenralInfo(false)
      } else if (response?.status === 401) {
        logout();
        setPageLoader(false)
        setEditGenralInfo(false)
        navigate("/");
      } else {
        setData([]);
        setPageLoader(false)
        setEditGenralInfo(false)
      }
    } catch (error) {
      setPageLoader(false)
      setEditGenralInfo(false)
      console.error("Error fetching data:", error);
    }
  };

  const getContact = async (event) => {
    try {
      setPageLoader(event)
      //call get profile API
      const response = await APIServices.get(
        `getProfile`,
        headers
      );
      if (response?.status == 200) {
        const resp = response?.data?.detail || [];
        //UPDATE ALL STATE FOR SHOW DATA
        const birthday = new Date(resp?.birthday);
        const defaultOption1 = countryOptions.find(option => option.value === resp?.phonenumberprefix);
        const defaultOption2 = countryOptions.find(option => option.value === resp?.backup_prefix);
        setData(resp);
        setEmail(resp?.email);
        setFirstName(resp?.first_name);
        setLastName(resp?.last_name);
        setScheduleDate(birthday);
        setEmail(resp?.email);
        setMobileNumber(resp?.phonenumber);
        setCountryCode(defaultOption1);
        setBackupCountryCode(defaultOption2);
        setBackupMobileNumber(resp?.backup_phone_number);
        let dateStr = resp?.last_login ?? "";
        let formattedDate = "";
        if (dateStr) {
          let date = new Date(dateStr);
          let day = String(date.getDate()).padStart(2, '0');
          let month = String(date.getMonth() + 1).padStart(2, '0'); // Month is 0-based, so add 1
          let year = date.getFullYear();
          // Get hours and minutes
          let hours = String(date.getHours()).padStart(2, '0');
          let minutes = String(date.getMinutes()).padStart(2, '0');
          // Format the full date and time
          formattedDate = `${day}/${month}/${year} ${hours}:${minutes}`;
        }
        setLastLogin(formattedDate);
        setTimeout(() => {
          setPageLoader(false)
        }, 500);
        setEditContactInfo(false)
      } else if (response?.status === 401) {
        logout();
        setPageLoader(false)
        setEditContactInfo(false)
        navigate("/");
      } else {
        setData([]);
        setPageLoader(false)
        setEditContactInfo(false)
      }
    } catch (error) {
      setPageLoader(false)
      setEditContactInfo(false)
      console.error("Error fetching data:", error);
    }
  };

  //get Additional details
  const getAdditionalInfo = async (event) => {

    try {
      setPageLoader(event)

      //call get profile API
      const response = await APIServices.get(
        `getAdditionalPerson`,
        headers
      );
      if (response?.status == 200) {
        const resp = response?.data?.detail || [];
        setAdditionalName(resp?.name);
        setAdditionalSurname(resp?.surname);
        setAdditionalAddress(resp?.address);
        setTimeout(() => {
          setPageLoader(false)
        }, 500);
        setEditAdditionalInfo(false)
      } else if (response?.status === 401) {
        logout();
        setPageLoader(false)
        setEditAdditionalInfo(false)
        navigate("/");
      } else {
        setPageLoader(false)
        setEditAdditionalInfo(false)
      }
    } catch (error) {
      setPageLoader(false)
      setEditAdditionalInfo(false)
      console.error("Error fetching data:", error);
    }
  };

  // get Control Question 
  const getControlQuestion = async (event) => {

    try {
      setPageLoader(event)

      //call get profile API
      const response = await APIServices.get(
        `getControlQuestion`,
        headers
      );
      if (response?.status == 200) {
        const resp = response?.data?.list || [];
        setQuestion(resp)
        setTimeout(() => {
          setPageLoader(false)
        }, 500);

      } else if (response?.status === 401) {
        logout();
        setPageLoader(false)
        navigate("/");

      } else {
        setPageLoader(false)
        setQuestion([])
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  //get Control Question and answer of users
  const getControlAnswer = async (event) => {
    try {
      setPageLoader(event)

      //call get profile API
      const response = await APIServices.get(
        `getControlAnswer`,
        headers
      );
      if (response?.status == 200) {
        const resp = response?.data?.detail || [];
        setAnswer(resp?.answer);
        setSelectedQuestion(resp?.question_id);
        setTimeout(() => {
          setPageLoader(false)
        }, 500);
        setEditQuestionInfo(false)
      } else if (response?.status === 401) {
        logout();
        setPageLoader(false)
        setEditQuestionInfo(false)
        navigate("/");
      } else {
        setPageLoader(false)
        setEditQuestionInfo(false)
      }
    } catch (error) {
      setPageLoader(false)
      setEditQuestionInfo(false)
      console.error("Error fetching data:", error);
    }
  };

  const genralInfoEdit = async (event) => {
    if (event == false) {
      getProfile(event);
    } else {
      setEditGenralInfo(event)
    }
  };
  const contactInfoEdit = async (event) => {
    if (event == false) {
      getContact(event);
    } else {
      setEditContactInfo(event)
    }
  };
  const additionalInfoEdit = async (event) => {
    if (event == false) {
      getAdditionalInfo(event);
    } else {
      setEditAdditionalInfo(event)
    }
  };
  const questionInfoEdit = async (event) => {
    if (event == false) {
      getControlAnswer(event)
    } else {
      setEditQuestionInfo(event)
    }
  };
  const safetyInfoEdit = async (event) => {
    setOldPassword("");
    setNewPassword("");
    setConfirmPassword("");
    setEditSafetyInfo(event)
  };

  const validateGenralInfo = () => {
    const errors = {};
    if (!firstName) {
      errors.firstName = "First name is required.";
    }
    if (!lastName) {
      errors.lastName = "Last name is required.";
    }
    if (!scheduleDate) {
      errors.scheduleDate = "Birthday date is required.";
    }
    setErrors(errors);
    return Object.keys(errors).length === 0;
  };
  //submit genral info
  const submitGenralInfo = async (e) => {
    e.preventDefault();
    if (validateGenralInfo()) {
      setLoad1(true);
      try {
        let payload = {
          first_name: firstName,
          last_name: lastName,
          birthday: scheduleDate,
        }
        //call API for profile update
        const res = await APIServices.post("updateProfile", payload, headers);
        if (res?.data?.status === true) {
          showToast(res?.data?.message || "Success", "success");
          setEditGenralInfo(false)
        } else {
          showToast(res?.data?.message || "Something went wrong.", "error");
        }
        setLoad1(false);
      } catch (error) {
        setLoad1(false);
        console.error("Error fetching data:", error);
      }
    }
  };

  const validateContactInfo = () => {
    const errors = {};
    if (!mobileNumber) {
      errors.mobileNumber = "Phone number is required.";
    }
    if (!backupMobileNumber) {
      errors.backupMobileNumber = "Backup phone number is required.";
    }
    if (!email) {
      errors.email = "Email is required.";
    }
    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  //submit contact info
  const submitContactInfo = async (e) => {
    e.preventDefault();
    if (validateContactInfo()) {
      setLoad2(true);
      try {
        let payload = {
          email: email,
          phone_number: mobileNumber,
          country_code: countryCode?.value,
          backup_phone_number: backupMobileNumber,
          backup_country_code: backupCountryCode?.value ? backupCountryCode?.value : "+1",
        }
        //call API for profile update
        const res = await APIServices.post("updateProfile", payload, headers);
        if (res?.data?.status === true) {
          showToast(res?.data?.message || "Success", "success");
          setEditContactInfo(false)
        } else {
          showToast(res?.data?.message || "Something went wrong.", "error");
        }
        setLoad2(false);
      } catch (error) {
        setLoad2(false);
        console.error("Error fetching data:", error);
      }
    }
  };


  const validateAdditionalInfo = () => {
    const errors = {};
    if (!additionalName) {
      errors.additionalName = "Name is required.";
    }
    if (!additionalSurname) {
      errors.additionalSurname = "Surname is required.";
    }
    if (!additionalAddress) {
      errors.additionalAddress = "Address is required.";
    }
    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  //Submit additional person info
  const submitAdditionalInfo = async (e) => {
    e.preventDefault();
    if (validateAdditionalInfo()) {
      setLoad3(true);
      try {
        let payload = {
          name: additionalName,
          surname: additionalSurname,
          address: additionalAddress
        }
        //call API for profile update
        const res = await APIServices.post("addAdditionalPeople", payload, headers);
        if (res?.data?.status === true) {
          showToast(res?.data?.message || "Success", "success");
          setEditAdditionalInfo(false)
        } else {
          showToast(res?.data?.message || "Something went wrong.", "error");
        }
        setLoad3(false);
      } catch (error) {
        setLoad3(false);
        console.error("Error fetching data:", error);
      }
    }

  };
  const validateQuestionInfo = () => {
    const errors = {};
    if (!answer) {
      errors.answer = "Answer is required.";
    }
    if (!questionSelected) {
      errors.questionSelected = "Select question is required.";
    }
    setErrors(errors);
    return Object.keys(errors).length === 0;
  };
  //submit question and answer of user
  const submitQuestionInfo = async (e) => {
    e.preventDefault();

    if (validateQuestionInfo()) {
      setLoad4(true);
      try {
        const question = questions.find(q => q.id == questionSelected);
        let payload = {
          email: email,
          question_id: questionSelected,
          question: question?.question,
          answer: answer
        }

        //call API for profile update
        const res = await APIServices.post("addControlQuestion", payload, headers);
        if (res?.data?.status === true) {
          showToast(res?.data?.message || "Success", "success");
          setEditQuestionInfo(false)
        } else {
          showToast(res?.data?.message || "Something went wrong.", "error");
        }
        setLoad4(false);
      } catch (error) {
        setLoad4(false);
        console.error("Error fetching data:", error);
      }
    }
  };


  const validateSafetyInfo = () => {
    const errors = {};
    if (!oldPassword) {
      errors.oldPassword = "oldPassword is required.";
    }
    if (!newPassword) {
      errors.newPassword = "New password is required.";
    }

    if (!confirmPassword) {
      errors.confirmPassword = "Confirm Password is required.";
    } else if (newPassword != confirmPassword) {
      errors.confirmPassword = "Password and confirm password dose not match.";
    }
    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  //submit safety info
  const submitSafetyInfo = async (e) => {
    e.preventDefault();
    if (validateSafetyInfo()) {
      setLoad5(true);
      try {
        let payload = {
          oldPassword: oldPassword,
          password: newPassword
        }
        //call API for profile update
        const res = await APIServices.post("changePassword", payload, headers);
        if (res?.data?.status === true) {
          showToast(res?.data?.message || "Success", "success")
          setEditSafetyInfo(false)
        } else {
          setEditSafetyInfo(false)
          showToast(res?.data?.message || "Something went wrong.", "error");
        }
        setLoad5(false);
        setEditSafetyInfo(false)

      } catch (error) {
        setLoad5(false);
        setEditSafetyInfo(false)

        console.error("Error fetching data:", error);
      }
    }

  };

  const toggleOldPasswordVisibility = () => setShowOldPassword(!showOldPassword);
  const toggleNewPasswordVisibility = () => setShowNewPassword(!showNewPassword);
  const toggleConfirmPasswordVisibility = () => setShowConfirmPassword(!showConfirmPassword);

  const countryOptions = [
    {
      value: "+1",
      label: (
        <div style={{ display: "flex", alignItems: "center" }}>
          +1
          <img
            src="/images/flags/us.png"
            alt="US"
            style={{ width: "20px", height: "18px", marginLeft: "6px" }}
          />
        </div>
      ),
    },
    {
      value: "+48",
      label: (
        <div style={{ display: "flex", alignItems: "center" }}>
          +48
          <img
            src="/images/flags/pl.png"
            alt="PL"
            style={{ width: "20px", height: "18px", marginLeft: "6px" }}
          />
        </div>
      ),
    },
    {
      value: "+91",
      label: (
        <div style={{ display: "flex", alignItems: "center" }}>
          +91
          <img
            src="/images/flags/in.png"
            alt="IN"
            style={{ width: "20px", height: "18px", marginLeft: "6px" }}
          />
        </div>
      ),
    },
    {
      value: "+44",
      label: (
        <div style={{ display: "flex", alignItems: "center" }}>
          +44
          <img
            src="/images/flags/gb.png"
            alt="UK"
            style={{ width: "20px", height: "18px", marginLeft: "6px" }}
          />
        </div>
      ),
    },
    {
      value: "+61",
      label: (
        <div style={{ display: "flex", alignItems: "center" }}>
          +61
          <img
            src="/images/flags/au.png"
            alt="AUS"
            style={{ width: "20px", height: "18px", marginLeft: "6px" }}
          />
        </div>
      ),
    },
    // Adding more countries
    {
      value: "+355",
      label: (
        <div style={{ display: "flex", alignItems: "center" }}>
          +355
          <img
            src="/images/flags/al.png"
            alt="AL"
            style={{ width: "20px", height: "18px", marginLeft: "6px" }}
          />
        </div>
      ),
    },
    {
      value: "+376",
      label: (
        <div style={{ display: "flex", alignItems: "center" }}>
          +376
          <img
            src="/images/flags/ad.png"
            alt="AD"
            style={{ width: "20px", height: "18px", marginLeft: "6px" }}
          />
        </div>
      ),
    },
    {
      value: "+43",
      label: (
        <div style={{ display: "flex", alignItems: "center" }}>
          +43
          <img
            src="/images/flags/at.png"
            alt="AT"
            style={{ width: "20px", height: "18px", marginLeft: "6px" }}
          />
        </div>
      ),
    },
    {
      value: "+32",
      label: (
        <div style={{ display: "flex", alignItems: "center" }}>
          +32
          <img
            src="/images/flags/be.png"
            alt="BE"
            style={{ width: "20px", height: "18px", marginLeft: "6px" }}
          />
        </div>
      ),
    },
    {
      value: "+387",
      label: (
        <div style={{ display: "flex", alignItems: "center" }}>
          +387
          <img
            src="/images/flags/ba.png"
            alt="BA"
            style={{ width: "20px", height: "18px", marginLeft: "6px" }}
          />
        </div>
      ),
    },
    {
      value: "+359",
      label: (
        <div style={{ display: "flex", alignItems: "center" }}>
          +359
          <img
            src="/images/flags/bg.png"
            alt="BG"
            style={{ width: "20px", height: "18px", marginLeft: "6px" }}
          />
        </div>
      ),
    },
    {
      value: "+45",
      label: (
        <div style={{ display: "flex", alignItems: "center" }}>
          +45
          <img
            src="/images/flags/dk.png"
            alt="DK"
            style={{ width: "20px", height: "18px", marginLeft: "6px" }}
          />
        </div>
      ),
    },
    {
      value: "+372",
      label: (
        <div style={{ display: "flex", alignItems: "center" }}>
          +372
          <img
            src="/images/flags/ee.png"
            alt="EE"
            style={{ width: "20px", height: "18px", marginLeft: "6px" }}
          />
        </div>
      ),
    },
    {
      value: "+358",
      label: (
        <div style={{ display: "flex", alignItems: "center" }}>
          +358
          <img
            src="/images/flags/fi.png"
            alt="FI"
            style={{ width: "20px", height: "18px", marginLeft: "6px" }}
          />
        </div>
      ),
    },
    {
      value: "+33",
      label: (
        <div style={{ display: "flex", alignItems: "center" }}>
          +33
          <img
            src="/images/flags/fr.png"
            alt="FR"
            style={{ width: "20px", height: "18px", marginLeft: "6px" }}
          />
        </div>
      ),
    },
    {
      value: "+49",
      label: (
        <div style={{ display: "flex", alignItems: "center" }}>
          +49
          <img
            src="/images/flags/de.png"
            alt="DE"
            style={{ width: "20px", height: "18px", marginLeft: "6px" }}
          />
        </div>
      ),
    },
    // Continue adding more European countries similarly...
  ]

  const questionOptions = [
    { value: '0', label: 'Select Question' },  // Default option
    ...questions.map((question) => ({
      value: question.id,   // Use the question's id as the value
      label: question.question, // Use the question text as the label
    })),
  ];

  const showToast = (message, type) => {
    toast(message, {
      position: "top-right",
      autoClose: 2000,
      type,
      hideProgressBar: false,
      closeOnClick: true,
    });
  };

  return (
    <AuthLayout>
      <div className="content-inner schedule-messaging">
        <div className={`overflow-content ${pageLoader ? "page_load" : ""}`}>
          {/* Profile desgin  end*/}
          {/* <div class="spinner-border text-info" role="status">
            <span class="visually-hidden">Loading...</span>
          </div> */}
          <div className="profile_tabs">
            <Nav tabs>
              <NavItem className={activeTab == "0" ? "currentActive" : ""}
                onClick={() => toggle("0")}>
                <NavLink

                >
                  <div>
                    <h4 className="card_heading font_12 mb-2">General Information</h4>
                  </div>
                </NavLink>
              </NavItem>
              <NavItem className={activeTab == "1" ? "currentActive" : ""}
                onClick={() => toggle("1")}>
                <NavLink

                >
                  <div >
                    <h4 className="card_heading font_12 mb-2">Safety Information</h4>
                  </div>
                </NavLink>
              </NavItem>
              <NavItem className={activeTab == "2" ? "currentActive" : ""}
                onClick={() => toggle("2")}>
                <NavLink

                >
                  <div >
                    <h4 className="card_heading font_12 mb-2">Access Rights For Users</h4>
                  </div>
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={activeTab}>
              <TabPane tabId="0">
                <div className="gen_info_wrapper p-3">
                  <div className="edit_profile_box mb-4">
                    <div className="mb-3">
                      <h2 className="font_black_16 font_700">General Information</h2>
                    </div>
                    <Form role="form" onSubmit={submitGenralInfo}>
                      <FormGroup className="mb-3 row align-items-start">
                        <div className="col-4">
                          <label for="name" class="form-label font_black_12 font_700">Name</label>
                        </div>
                        <div className="col-8">
                          <Input
                            className="form-control form_input font_black_12"
                            id="first_name"
                            name="first_name"
                            placeholder="Name"
                            type="text"
                            onChange={(e) => setFirstName(e.target.value)}
                            value={firstName ?? data?.first_name}
                            readOnly={!editGenralInfo}
                            disabled={!editGenralInfo}
                          />
                          {errors.firstName && (
                            <div className="errorVal ">
                              {errors.firstName}
                            </div>
                          )}
                        </div>
                      </FormGroup>
                      <FormGroup className="mb-3 row align-items-start">
                        <div className="col-4">
                          <label for="name" class="form-label font_black_12 font_700">Surname</label>
                        </div>
                        <div className="col-8">
                          <Input
                            className="form-control form_input font_black_12"
                            id="last_name"
                            name="last_name"
                            placeholder="Surname"
                            type="text"
                            onChange={(e) => setLastName(e.target.value)}
                            value={lastName ?? data?.last_name}
                            readOnly={!editGenralInfo}
                            disabled={!editGenralInfo}

                          />
                          {errors.lastName && (
                            <div className="errorVal ">
                              {errors.lastName}
                            </div>
                          )}
                        </div>
                      </FormGroup>


                      <FormGroup className=" mb-3 row align-items-start">
                        <Col sm="4" className="pe-0">
                          <label htmlFor="scheduleDate" className="form-label font_black_12 font_700">
                            Birthday
                          </label>
                        </Col>
                        <Col sm="8" className="schedule_datepicker">
                          <DatePicker
                            className="form-control form_input font_black_12"
                            dateFormat="MM/dd/yyyy" // Show month, day, and year in the format MM/DD/YYYY
                            showMonthDropdown
                            maxDate={today} // Prevent selecting dates before today
                            locale="en-GB" // Use the English (UK) locale
                            placeholderText="MM/DD/YYYY" // Placeholder text when the input is empty
                            openToDate={today} // Force the calendar to open to today's date
                            disabled={!editGenralInfo}
                            onChange={(date) => setScheduleDate(date)} // Handle the date directly
                            selected={scheduleDate} // Set the default date to today
                          />
                          <img src="/images/datepicker_icon.svg" className="datepicker_icon" />
                          {errors.scheduleDate && (
                            <div className="errorVal ">
                              {errors.scheduleDate}
                            </div>
                          )}
                        </Col>
                      </FormGroup>
                      <FormGroup className="mb-3 mt-1 align-items-start justify-content-end template_buttons">
                        {editGenralInfo ? (
                          <>
                            <Link to="/user/profile">
                              <Button onClick={() => genralInfoEdit(false)} className="btn primary-btn font_700" type="button">
                                Cancel
                              </Button>
                            </Link>
                            {load1 ? (
                              <Button className="btn navy_btn font_700" disabled="true">
                                Saving...
                              </Button>
                            ) : (<Button className="btn navy_btn font_700" type="submit">
                              Save Changes
                            </Button>)}
                          </>
                        ) : (
                          <Button onClick={() => genralInfoEdit(true)} className="btn primary-btn font_700" type="button">
                            Edit
                          </Button>
                        )}
                      </FormGroup>


                    </Form>
                  </div>
                  <div className="edit_profile_box">
                    <div className="mb-3">
                      <h2 className="font_black_16 font_700">Contact Information</h2>
                    </div>
                    <Form role="form" onSubmit={submitContactInfo}>
                      <FormGroup className="mb-3 row align-items-start">
                        <Col sm="4" className="pe-0">
                          <label htmlFor="recipientNumber" className="form-label font_black_12 font_700">
                            Phone Number
                          </label>
                        </Col>
                        <Col sm="8">
                          <InputGroup className="d-flex align-items-start phn_code">
                            <Select
                              className="form_input down_arw"
                              classNamePrefix="react-select"
                              options={countryOptions}
                              placeholder="Country"
                              value={countryCode} // Pass the selected country as the value
                              isSearchable={true}
                              onChange={(countryCode) => setCountryCode(countryCode)}
                              readOnly={!editContactInfo}
                              isDisabled={!editContactInfo}
                            />
                            <Input
                              className="form-control form_input font_black_12"
                              id="phoneNumber"
                              name="phoneNumber"
                              placeholder="Enter Phone No"
                              type="tel"
                              value={mobileNumber ?? data?.phonenumber}
                              maxLength={12}
                              onChange={(e) => setMobileNumber(e.target.value)}
                              readOnly={!editContactInfo}
                              disabled={!editContactInfo}
                              style={{ borderRadius: "8px", marginLeft: "5px" }}
                            />
                          </InputGroup>
                          {errors.mobileNumber && (
                            <div className="errorVal ">
                              {errors.mobileNumber}
                            </div>
                          )}
                        </Col>
                      </FormGroup>
                      <FormGroup className="mb-3 row align-items-start">
                        <Col sm="4" className="pe-0">
                          <label htmlFor="recipientNumber" className="form-label backup-phone font_black_12 font_700">
                            Backup Phone Number
                          </label>
                        </Col>
                        <Col sm="8">
                          <InputGroup className="d-flex align-items-start phn_code">
                            <Select
                              className="form_input down_arw"
                              classNamePrefix="react-select"
                              options={countryOptions}
                              placeholder="Country"
                              value={backupCountryCode} // Pass the selected country as the value
                              isSearchable={false}
                              onChange={(backupCountryCode) => setBackupCountryCode(backupCountryCode)}
                              readOnly={!editContactInfo}
                              isDisabled={!editContactInfo}
                            />
                            <Input
                              className="form-control form_input font_black_12"
                              id="backupPhoneNumber"
                              name="backupPhoneNumber"
                              placeholder="Enter Backup Phone No"
                              type="tel"
                              value={backupMobileNumber ?? data?.backup_phone_number}
                              maxLength={12}
                              onChange={(e) => setBackupMobileNumber(e.target.value)}
                              readOnly={!editContactInfo}
                              disabled={!editContactInfo}
                              style={{ borderRadius: "8px", marginLeft: "5px" }}
                            />
                          </InputGroup>
                          {errors.backupMobileNumber && (
                            <div className="errorVal ">
                              {errors.backupMobileNumber}
                            </div>
                          )}
                        </Col>
                      </FormGroup>

                      <FormGroup className="mb-3 row align-items-start">
                        <div className="col-4">
                          <label for="name" class="form-label font_black_12 font_700">Email</label>
                        </div>
                        <div className="col-8">
                          <Input
                            className="form-control form_input font_black_12 mb-3"
                            id="email"
                            name="email"
                            placeholder="Enter Email"
                            type="email"
                            value={email ?? data?.email}
                            onChange={(e) => setEmail(e.target.value)}
                            readOnly={!editContactInfo}
                            disabled={!editContactInfo}
                          />
                          {errors.email && (
                            <div className="errorVal ">
                              {errors.email}
                            </div>
                          )}
                        </div>
                      </FormGroup>

                      <FormGroup className="mb-3 mt-1 align-items-start justify-content-end template_buttons">
                        {editContactInfo ? (
                          <>
                            <Link to="/user/profile">
                              <Button onClick={() => contactInfoEdit(false)} className="btn primary-btn font_700" type="button">
                                Cancel
                              </Button>
                            </Link>
                            {load2 ? (
                              <Button className="btn navy_btn font_700" disabled="true">
                                Saving...
                              </Button>
                            ) : (<Button className="btn navy_btn font_700" type="submit">
                              Save Changes
                            </Button>)}
                          </>
                        ) : (
                          <Button onClick={() => contactInfoEdit(true)} className="btn primary-btn font_700" type="button">
                            Edit
                          </Button>
                        )}
                      </FormGroup>
                    </Form>
                  </div>
                </div>
              </TabPane>

              <TabPane tabId="1">
                <div className="safety_info_wrapper p-3">
                  <div className="mb-3">
                    <h2 className="font_black_16 font_700">Safety Information</h2>
                    <p className="font_10 font_400"><span className="font_700">Last Login At:</span> {lastLogin} </p>
                  </div>
                  <div className="edit_profile_box mb-4">
                    <div className="mb-3">
                      <h2 className="font_black_16 font_700">Password</h2>
                    </div>
                    <Form role="form" onSubmit={submitSafetyInfo}>
                      <FormGroup className="mb-3 row align-items-start">
                        <Col sm="4" className="pe-0">
                          <label htmlFor="password" className="font_12 font_700">Old Password</label>
                        </Col>
                        <Col sm="8">
                          <div className="input_box position-relative">
                            <div className="position-relative">
                              <Input
                                className="form-control form_input font_12 password-input"
                                placeholder="Passcode"
                                type={showOldPassword ? "text" : "password"} // Toggle between text and password
                                id="password"
                                name="password"
                                autoComplete="new-password"
                                maxLength={20}
                                value={oldPassword ?? ""}
                                readOnly={!editSafetyInfo}
                                disabled={!editSafetyInfo}
                                onChange={(e) => setOldPassword(e.target.value)}
                              />
                              <span
                                className="hide-text font_12"
                                onClick={toggleOldPasswordVisibility} // Toggle password visibility on click
                                style={{ cursor: 'pointer', position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)' }}
                              >

                                <img src="/images/eye.svg" className="img-fluid eye_icon" alt="logo" />
                              </span>
                            </div>
                            {errors.oldPassword && (
                              <div className="errorVal">{errors.oldPassword}</div>
                            )}
                          </div>
                        </Col>
                      </FormGroup>
                      <FormGroup className="mb-3 row align-items-start">
                        <Col sm="4" className="pe-0">
                          <label htmlFor="password" className="font_12 font_700">New Password</label>
                        </Col>
                        <Col sm="8">
                          <div className="input_box position-relative">
                            <div className="position-relative">
                              <Input
                                className="form-control form_input font_12 password-input"
                                placeholder="Passcode"
                                type={showNewPassword ? "text" : "password"} // Toggle between text and password
                                id="newPassword"
                                value={newPassword ?? ""}
                                name="newPassword"
                                autoComplete="new-password"
                                maxLength={20}
                                readOnly={!editSafetyInfo}
                                disabled={!editSafetyInfo}
                                onChange={(e) => setNewPassword(e.target.value)}
                              />
                              <span
                                className="hide-text font_12"
                                onClick={toggleNewPasswordVisibility} // Toggle password visibility on click
                                style={{ cursor: 'pointer', position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)' }}
                              >

                                <img src="/images/eye.svg" className="img-fluid eye_icon" alt="logo" />
                              </span>
                            </div>
                            {errors.newPassword && (
                              <div className="errorVal">{errors.newPassword}</div>
                            )}
                          </div>
                        </Col>
                      </FormGroup>
                      <FormGroup className="mb-3 row align-items-start">
                        <Col sm="4" className="pe-0">
                          <label htmlFor="password" className="font_12 font_700">Confirm Password</label>
                        </Col>
                        <Col sm="8">
                          <div className="input_box position-relative">
                            <div className="position-relative">
                              <Input
                                className="form-control form_input font_12 password-input"
                                placeholder="Passcode"
                                type={showConfirmPassword ? "text" : "password"} // Toggle between text and password
                                id="confirmPassword"
                                name="confirmPassword"
                                autoComplete="new-password"
                                maxLength={20}
                                value={confirmPassword ?? ""}
                                readOnly={!editSafetyInfo}
                                disabled={!editSafetyInfo}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                              />
                              <span
                                className="hide-text font_12"
                                onClick={toggleConfirmPasswordVisibility} // Toggle password visibility on click
                                style={{ cursor: 'pointer', position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)' }}
                              >

                                <img src="/images/eye.svg" className="img-fluid eye_icon" alt="logo" />
                              </span>
                            </div>
                            {errors.confirmPassword && (
                              <div className="errorVal">{errors.confirmPassword}</div>
                            )}
                          </div>
                        </Col>
                      </FormGroup>
                      <FormGroup className="mb-3 mt-1 align-items-start justify-content-end template_buttons">
                        {editSafetyInfo ? (
                          <>
                            <Link to="/user/profile">
                              <Button onClick={() => safetyInfoEdit(false)} className="btn primary-btn font_700" type="button">
                                Cancel
                              </Button>
                            </Link>
                            {load5 ? (
                              <Button className="btn navy_btn font_700" disabled="true">
                                Saving...
                              </Button>
                            ) : (<Button className="btn navy_btn font_700" type="submit">
                              Save Changes
                            </Button>)}
                          </>
                        ) : (
                          <Button onClick={() => safetyInfoEdit(true)} className="btn primary-btn font_700" type="button">
                            Edit
                          </Button>
                        )}
                      </FormGroup>
                    </Form>
                  </div>
                  <div className="edit_profile_box mb-4">
                    <div className="mb-3">
                      <h2 className="font_black_16 font_700">Control Question</h2>
                      <p className="font_10 font_700">A control question is needed to restore access if you cannot restore access to your account via email or phone number</p>
                    </div>
                    <Form role="form" onSubmit={submitQuestionInfo}>
                      <FormGroup className="mt-2 mb-3 row align-items-start">
                        <Col sm="4" className="pe-0">
                          <label htmlFor="repeat" className="form-label font_black_12 font_700">
                            Control Question
                          </label>
                        </Col>
                        <Col sm="8">
                          <div className="down_arw">
                            {/* <Input
                              type="select"
                              id="question"
                              name="question"
                              className="form-control form_input font_black_12"
                              value={questionSelected}
                              onChange={(e) => setSelectedQuestion(e.target.value)}
                              readOnly={!editQuestionInfo}
                              disabled={!editQuestionInfo}
                            >
                              <option value="0">Select Question</option>
                              {questions.map((question) => (
                                <option key={question.id} value={question.id}>
                                  {question.question}
                                </option>
                              ))}
                            </Input> */}
                            <Select
                              className="form_input down_arw"
                              classNamePrefix="react-select"
                              value={questionOptions ? questionOptions.find(option => option.value == questionSelected) : null} // Find the selected option
                              onChange={(selectedOption) => setSelectedQuestion(selectedOption ? selectedOption.value : null)} // Handle change and update state
                              options={questionOptions}
                              isDisabled={!editQuestionInfo} // Disable if not editing
                              isReadOnly={!editQuestionInfo} // Set readonly if not editing (react-select doesn't have a direct readonly prop)
                              placeholder="Select Question" // Add placeholder if needed
                            />
                            {errors.questionSelected && (
                              <div className="errorVal ">
                                {errors.questionSelected}
                              </div>
                            )}
                          </div>
                        </Col>
                      </FormGroup>
                      <FormGroup className="mb-3 row align-items-start">
                        <div className="col-4">
                          <label for="name" class="form-label font_black_12 font_700">Answer</label>
                        </div>
                        <div className="col-8">
                          <Input
                            className="form-control form_input font_black_12 mb-3"
                            id="answer"
                            name="answer"
                            placeholder="Answer"
                            type="text"
                            value={answer}
                            onChange={(e) => setAnswer(e.target.value)}
                            readOnly={!editQuestionInfo}
                            disabled={!editQuestionInfo}
                          />
                          {errors.answer && (
                            <div className="errorVal ">
                              {errors.answer}
                            </div>
                          )}
                        </div>
                      </FormGroup>
                      <FormGroup className="mb-3 mt-1 align-items-start justify-content-end template_buttons">
                        {editQuestionInfo ? (
                          <>
                            <Link to="/user/profile">
                              <Button onClick={() => questionInfoEdit(false)} className="btn primary-btn font_700" type="button">
                                Cancel
                              </Button>
                            </Link>
                            {load4 ? (
                              <Button className="btn navy_btn font_700" disabled="true">
                                Saving...
                              </Button>
                            ) : (<Button className="btn navy_btn font_700" type="submit">
                              Save Changes
                            </Button>)}
                          </>
                        ) : (
                          <Button onClick={() => questionInfoEdit(true)} className="btn primary-btn font_700" type="button">
                            Edit
                          </Button>
                        )}
                      </FormGroup>
                    </Form>
                  </div>
                  <div className="edit_profile_box">
                    <div className="mb-3">
                      <h2 className="font_black_16 font_700">Additional Person</h2>
                      <p className="font_10 font_700">Details of the person who can access your account if you are unable to use your account</p>
                    </div>
                    <Form role="form" onSubmit={submitAdditionalInfo}>
                      <FormGroup className="mb-3 row align-items-start">
                        <div className="col-4">
                          <label for="name" class="form-label font_black_12 font_700">Name</label>
                        </div>
                        <div className="col-8">
                          <Input
                            className="form-control form_input font_black_12"
                            id="first_name"
                            name="first_name"
                            placeholder="Name"
                            type="text"
                            value={additionalName}
                            onChange={(e) => setAdditionalName(e.target.value)}
                            readOnly={!editAdditionalInfo}
                            disabled={!editAdditionalInfo}
                          />
                          {errors.additionalName && (
                            <div className="errorVal ">
                              {errors.additionalName}
                            </div>
                          )}
                        </div>
                      </FormGroup>
                      <FormGroup className="mb-3 row align-items-start">
                        <div className="col-4">
                          <label for="name" class="form-label font_black_12 font_700">Surname</label>
                        </div>
                        <div className="col-8">
                          <Input
                            className="form-control form_input font_black_12"
                            id="first_name"
                            name="first_name"
                            placeholder="surname"
                            type="text"
                            value={additionalSurname}
                            onChange={(e) => setAdditionalSurname(e.target.value)}
                            readOnly={!editAdditionalInfo}
                            disabled={!editAdditionalInfo}
                          />
                          {errors.additionalSurname && (
                            <div className="errorVal ">
                              {errors.additionalSurname}
                            </div>
                          )}
                        </div>
                      </FormGroup>
                      <FormGroup className="mb-3 row align-items-start">
                        <div className="col-4">
                          <label for="name" class="form-label font_black_12 font_700 mt-2">Address</label>
                        </div>
                        <div className="col-8">
                          <Input
                            className="form-control form_input font_black_12 h-auto"
                            id="yourMessage"
                            name="yourMessage"
                            placeholder="Address"
                            type="textarea"
                            rows="4"
                            value={additionalAddress}
                            maxLength="100"
                            onChange={(e) => setAdditionalAddress(e.target.value)}
                            readOnly={!editAdditionalInfo}
                            disabled={!editAdditionalInfo}
                          />
                          {errors.additionalAddress && (
                            <div className="errorVal ">
                              {errors.additionalAddress}
                            </div>
                          )}
                        </div>
                      </FormGroup>
                      <FormGroup className="mb-3 mt-1 align-items-start justify-content-end template_buttons">
                        {editAdditionalInfo ? (
                          <>
                            <Link to="/user/profile">
                              <Button onClick={() => additionalInfoEdit(false)} className="btn primary-btn font_700" type="button">
                                Cancel
                              </Button>
                            </Link>
                            {load3 ? (
                              <Button className="btn navy_btn font_700" disabled="true">
                                Saving...
                              </Button>
                            ) : (<Button className="btn navy_btn font_700" type="submit">
                              Save Changes
                            </Button>)}
                          </>
                        ) : (
                          <Button onClick={() => additionalInfoEdit(true)} className="btn primary-btn font_700" type="button">
                            Edit
                          </Button>
                        )}
                      </FormGroup>
                    </Form>
                  </div>
                </div>
              </TabPane>
              <TabPane tabId="2">
                <div className="access_right_wrapper p-3">
                  <div className="edit_profile_box">
                    <div className="mb-3">
                      <h2 className="font_black_16 font_700">Access Rights For Users</h2>
                    </div>
                  </div>
                </div>
              </TabPane>
            </TabContent>
          </div>
        </div>
      </div>
      <ToastContainer />
    </AuthLayout>

  );
};

export default Profile;
