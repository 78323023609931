import { useContext, useEffect, useState } from "react";
import AuthLayout from "../components/PublicLayout/authLayout";
import { useNavigate, Link, useLocation } from "react-router-dom";

import {
  CardHeader,
  Row,
  Col,
  Button,
  Input,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  FormGroup,
  Form
} from "reactstrap";
import APIServicenew from "../utils/APIGeneralService";
import { UserContext } from "../context/UserContextProvider";
import "react-toastify/dist/ReactToastify.css";
import "react-datepicker/dist/react-datepicker.css";
import { ToastContainer, toast } from "react-toastify";
import 'react-datepicker/dist/react-datepicker.css'; // Import the CSS for the date picker

const AIAvatarsPersonalization = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get('id');
  const APIServices = new APIServicenew(process.env.REACT_APP_OTHER_API_URL);
  const authToken = process.env.REACT_APP_authToken;
  const { accessToken, logout } = useContext(UserContext);
  const headers = { "auth-token": authToken, authorization: accessToken };
  const [data1, setData1] = useState([]);
  const [thoughts, setThoughts] = useState();
  const [avatarName, setAvatarName] = useState();
  const [avatarId, setAvatarId] = useState(id);
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState("0");
  const [activeSubTab, setActiveSubTab] = useState("1");
  const [errors, setErrors] = useState({});
  const [load, setLoad] = useState(false);


  // validation function SMS
  const validate = () => {
    const errors = {};
    if (!thoughts) {
      errors.thoughts = "Please write your thoughts.";
    }
    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const cancel = () => {
    setThoughts('');
  };
  //handle submit details
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoad(true);
    if (validate()) {
      try {
        let payload = {
          id: avatarId,
          thoughts: thoughts,
        };
        const res = await APIServices.post("addAvatarThoughts", payload, headers);
        if (res?.data?.status === true) {
          showToast(res?.data?.message || "Success", "success");

        } else {
          showToast(res?.data?.message || "Something went wrong.", "error");
        }
      } catch (error) {
        showToast("An error occurred while adding the thoughts.", "error");
        console.error("Error adding thoughts:", error);
      }
    }
    setLoad(false);
  };

  //get list of message from DB
  const fetchData = async (avatarId) => {
    setLoading(true)
    try {
      const queryParams = [];
      if (avatarId) queryParams.push(`id=${avatarId}`);
      const queryString =
        queryParams.length > 0 ? `?${queryParams.join("&")}` : "";
      const response = await APIServices.get(
        `getAvatarDetails${queryString}`,
        headers
      );
      if (response?.status === 200) {
        const data = response?.data?.list || [];
        setData1(data);
        setThoughts(data.own_thoughts);
        setAvatarName(data.avatar_name);
      } else if (response?.status === 401) {
        logout();
        navigate("/");
      } else {
        setData1([]);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setData1([]);
    }
    setLoading(false)
  };

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  const subToggle = (tab) => {
    if (activeSubTab !== tab) setActiveSubTab(tab);
  };

  useEffect(() => {
    if (avatarId != null) {
      fetchData(avatarId);
    }
  }, [avatarId]);
  //show success and fail message
  const showToast = (message, type) => {
    toast(message, {
      position: "top-right",
      autoClose: 2000,
      type,
      hideProgressBar: false,
      closeOnClick: true,
    });
  };

  return (
    <AuthLayout>
      <div className="content-inner messaging">
        <div className="overflow-content">
          <div className="customizer_wrapper">
            <Nav tabs>
              <NavItem>
                <NavLink href="/user/message?tab=1"
                  className={activeTab === "1" ? "active" : ""}
                  onClick={() => toggle("1")}
                >
                  <div className="customizer_link bg-black-theme" href="#">
                    <img src="/images/sms_icon.svg" className="customizer_card_icon" />
                    <h4 className="card_heading font_16 mb-2 font_700">SMS</h4>
                    <p className="card_para font_12">Send text message to phone without media</p>
                  </div>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="/user/message?tab=2"
                  className={activeTab === "2" ? "active" : ""}
                  onClick={() => toggle("2")}
                >
                  <div className="customizer_link bg-black-theme" href="#">
                    <img src="/images/MMS_icon.svg" className="customizer_card_icon" />
                    <h4 className="card_heading font_16 mb-2 font_700">MMS</h4>
                    <p className="card_para font_12">Send text message to phone with media: images, ringtones, videos</p>
                  </div>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="/user/message?tab=3"
                  className={activeTab === "3" ? "active" : ""}
                  onClick={() => toggle("3")}
                >
                  <div className="customizer_link bg-black-theme" href="#">
                    <img src="/images/MMS_icon.svg" className="customizer_card_icon" />
                    <h4 className="card_heading font_16 mb-2 font_700">E-Mail</h4>
                    <p className="card_para font_12">Send email with media: images, ringtones, videos</p>
                  </div>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="/user/message?tab=4"
                  className={activeTab === "4" ? "active" : ""}
                  onClick={() => toggle("4")}
                >
                  <div className="customizer_link bg-black-theme" href="#">
                    <img src="/images/MMS_icon.svg" className="customizer_card_icon" />
                    <h4 className="card_heading font_16 mb-2 font_700">Paper Letter</h4>
                    <p className="card_para font_12">Write a message, add an image, and we will print and deliver it!</p>
                  </div>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="/user/message?tab=5"
                  className={activeTab === "5" ? "active" : ""}
                  onClick={() => toggle("5")}
                >
                  <div className="customizer_link bg-black-theme" href="#">
                    <img src="/images/MMS_icon.svg" className="customizer_card_icon" />
                    <h4 className="card_heading font_16 mb-2 font_700">Add Item In Storage</h4>
                    <p className="card_para font_12">Write a message, add an image, and we will print and deliver it!</p>
                  </div>
                </NavLink>
              </NavItem>
            </Nav>
            <div className="padding_pg ai_avatar">
              <CardHeader className="border-0 bg-white-theme p-0 mb-4">
                <Link to="/user/ai-avatars">
                  <Button className="btn primary-btn back_list_btn font_12 font_700">
                    Back To List
                  </Button>
                </Link>
                <img src="/images/polygon1.svg" className="side_arrow" />
                <span className="font_16 font_700 text-capitalize me-3">{avatarName ?? ""}</span>
                <span className="personalize_btn">
                  Personalization
                </span>
              </CardHeader>
                <div className="d-flex align-items-start gap-3 ms-3 mb-3">
                  <h3 className="delivery_heading font_16 font_700 mt-3">AI Personality</h3>
                </div>
              <div className="schedule-messaging">
                <div className="overflow-content">
                  <div className="profile_tabs">
                    <Nav tabs className="mb-1">
                      <NavItem className={activeSubTab == "0" ? "currentActive" : ""}
                        onClick={() => subToggle("0")}>
                        <NavLink className="text_black"

                        >
                          <div>
                            <h4 className="card_heading font_12 mb-2">Answers To Questions</h4>
                          </div>
                        </NavLink>
                      </NavItem>
                      <NavItem className={activeSubTab == "1" ? "currentActive" : ""}
                        onClick={() => subToggle("1")}>
                        <NavLink className="text_black"

                        >
                          <div >
                            <h4 className="card_heading font_12 mb-2">Own Thoughts</h4>
                          </div>
                        </NavLink>
                      </NavItem>
                      <NavItem className={activeSubTab == "2" ? "currentActive" : ""}
                        onClick={() => subToggle("2")}>
                        <NavLink className="text_black"

                        >
                          <div >
                            <h4 className="card_heading font_12 mb-2">History Of Personality</h4>
                          </div>
                        </NavLink>
                      </NavItem>
                    </Nav>
                    <TabContent activeTab={activeSubTab}>
                      <TabPane tabId="0">
                        <div className="gen_info_wrapper p-3">
                          <div className="edit_profile_box mb-4">
                            <div className="mb-3">
                              <h2 className="font_black_16 font_700">Answers To Questions</h2>
                            </div>
                          </div>

                        </div>
                      </TabPane>
                      <TabPane tabId="1">
                        <div className="safety_info_wrapper">
                          <p class="card_para font_12">Write your thoughts in free form, what you think, what you feel, what you would like to tell <br></br>your relatives, friends, and loved ones.</p>
                          <Col sm="12" md="12" lg="8" xl="5">
                            <Form role="form" onSubmit={handleSubmit}>
                              <FormGroup className="mt-4">
                                <div className="d-flex position-relative ">
                                  <Input
                                    className="form-control form_input font_black_12 h-auto"
                                    id="thoughts"
                                    name="thoughts"
                                    placeholder="Your Thoughts"
                                    type="textarea"
                                    rows="18"
                                    value={thoughts}
                                    maxLength="1000"
                                    onChange={(e) => setThoughts(e.target.value)}
                                  />
                                  <span class="textarea_count">{thoughts?.length}/1000</span>
                                </div>
                                {errors.thoughts && (
                                  <div className="errorVal mt-2">
                                    {errors.thoughts}
                                  </div>
                                )}
                              </FormGroup>
                              <FormGroup className="mb-0  template_buttons justify-content-end mt-3">
                                <Button className="btn smoke_btn font_700" type="button" onClick={cancel}>
                                  Clear
                                </Button>
                                {load ? (
                                  <Button className="btn smoke_btn  font_700" disabled>
                                    Processing...
                                  </Button>
                                ) : (
                                  <Button className="btn navy_btn font_700" type="submit">
                                    Save And Next
                                  </Button>
                                )}
                              </FormGroup>
                            </Form>
                          </Col>
                        </div>
                      </TabPane>
                      <TabPane tabId="2">
                        <div className="access_right_wrapper p-3">
                          <div className="edit_profile_box">
                            <div className="mb-3">
                              <h2 className="font_black_16 font_700">History Of Personality</h2>
                            </div>
                          </div>
                        </div>
                      </TabPane>
                    </TabContent>
                  </div>
                </div>
              </div>



            </div>
          </div>
        </div>
      </div >


      <ToastContainer />

    </AuthLayout >
  );
};

export default AIAvatarsPersonalization;
