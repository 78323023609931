import { useContext, useEffect, useState } from "react";
import AuthLayout from "../components/PublicLayout/authLayout";
import { useNavigate, Link, useLocation } from "react-router-dom";
import moment from "moment";

import {
  Card,
  CardHeader,
  Row,
  Col,
  Button,
  Input,
  Nav,
  NavItem,
  NavLink,
  FormGroup,
  Form
} from "reactstrap";
import PaginatedTable from "../components/Table/PaginatedTable";
import APIServicenew from "../utils/APIGeneralService";
import { UserContext } from "../context/UserContextProvider";
import "react-toastify/dist/ReactToastify.css";
import "react-datepicker/dist/react-datepicker.css";
import { ToastContainer, toast } from "react-toastify";
import DeleteModal from "../components/Modal/deleteModal";

import Select from 'react-select'
import 'react-datepicker/dist/react-datepicker.css'; // Import the CSS for the date picker

const AIAvatars = (props) => {
  const navigate = useNavigate();
  const APIServices = new APIServicenew(process.env.REACT_APP_OTHER_API_URL);
  const authToken = process.env.REACT_APP_authToken;
  const { accessToken, logout } = useContext(UserContext);
  const headers = { "auth-token": authToken, authorization: accessToken };
  const [limit, setLimit] = useState(10);
  const [data1, setData1] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [keyword, setKeyword] = useState("");
  const [totalCount, setTotalCount] = useState(0);
  const [count, setCount] = useState(0);
  const offset = (currentPage - 1) * limit;
  const [sort, setSort] = useState("newest");
  const [avatarName, setAvatarName] = useState();
  const [description, setDescription] = useState();
  const [showDt, setShowDt] = useState(false);
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState("0");
  const [errors, setErrors] = useState({});
  const [load, setLoad] = useState(false);
  const [selectedDeleteMsg, setSelectedDeleteMsg] = useState(null);
  const [avatarId, setAvatarId] = useState();
  const [avatarData, setAvatarData] = useState([]);
  const sortOptions = [
    { value: 'newest', label: 'Newest' },
    { value: 'oldest', label: 'Oldest' },
  ];
  // validation function SMS
  const validate = () => {
    const errors = {};
    if (!avatarName) {
      errors.avatarName = "Avatar name is required.";
    }
    if (!description) {
      errors.description = "Description is required.";
    }

    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const cancel = () => {
    setAvatarId('');
    setAvatarName('');
    setDescription('');
  };

  //handle delete message 
  const handleDelete = async () => {
    try {
      if (selectedDeleteMsg !== null) {
        const payload = { id: selectedDeleteMsg };
        //call delete message api
        const res = await APIServices.post(`/deleteAvatar`, payload, headers);
        if (res?.status === 200) {
          showToast(res?.data?.message || "Success", "success");
          setSelectedDeleteMsg(null);
          //fetchData();
          const updatedData = data1.filter(item => item.id !== selectedDeleteMsg);
          setData1(updatedData);
          setCount(updatedData.length);
          const totalItems = updatedData.length || 0;
          setTotalCount(Math.ceil(totalItems / limit));
          setCurrentPage(1);
        } else {
          showToast(res?.data?.message || "Something went wrong.", "error");
        }
      }
      setShowDt(false);
    } catch (error) {
      console.error("Error deleting Message:", error);
    }
  };
  //handle submit details
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoad(true);
    if (validate()) {
      // Create the payload object
      if (avatarId && avatarId != "") {
        try {
          let payload = {
            id: avatarId,
            avatarName: avatarName,
            description: description,
          };
          //call API for add message
          const res = await APIServices.post("editAvatar", payload, headers);
          if (res?.data?.status === true) {
            showToast(res?.data?.message || "Success", "success");
            setAvatarId();
            fetchData();
            setAvatarId('');
            setAvatarName('');
            setDescription('');
          } else {
            setAvatarId();
            showToast(res?.data?.message || "Something went wrong.", "error");
          }
        } catch (error) {
          setAvatarId();
          showToast("An error occurred while adding the message.", "error");
          console.error("Error adding message:", error);
        }
      } else {
        try {
          let payload = {
            avatarName: avatarName,
            description: description,
          };
          //call API for add message
          const res = await APIServices.post("addAvatar", payload, headers);
          if (res?.data?.status === true) {
            showToast(res?.data?.message || "Success", "success");
            fetchData();
            setAvatarId('');
            setAvatarName('');
            setDescription('');
          } else {
            showToast(res?.data?.message || "Something went wrong.", "error");
          }
        } catch (error) {
          showToast("An error occurred while adding the message.", "error");
          console.error("Error adding message:", error);
        }
      }



      setLoad(false);
    } else {
      setLoad(false);
    }
  };

  //edit 
  const editAvatar = async (row) => {
    let id = row?.id;
    setLoading(true)
    setAvatarId(row?.id)
    try {

      if (row?.id) {
        setAvatarData(row)
      } else {
        console.error("Error fetching data:");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setAvatarData([]);
    }
    setDescription(row?.description)
    setAvatarName(row?.avatar_name)
    setLoading(false)
  };

  //get list of message from DB
  const fetchData = async (page = 1) => {
    const skip = page;
    setLoading(true)
    try {
      //add filtets
      const queryParams = [];
      if (keyword) queryParams.push(`keyword=${keyword}`);
      if (limit) queryParams.push(`limit=${limit}`);
      if (skip) queryParams.push(`offset=${skip}`);
      if (sort) queryParams.push(`sort_by=${sort}`);
      //call message list API
      const queryString =
        queryParams.length > 0 ? `?${queryParams.join("&")}` : "";
      const response = await APIServices.get(
        `getAvatarList${queryString}`,
        headers
      );
      if (response?.status === 200) {
        const data = response?.data?.list || [];
        setData1(data);
        setCount(response?.data?.count);
        const totalItems = response?.data?.count || 0;
        setTotalCount(Math.ceil(totalItems / limit));
      } else if (response?.status === 401) {
        logout();
        navigate("/");
      } else {
        setData1([]);
        setTotalCount(0);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setData1([]);
      setTotalCount(0);
    }
    setLoading(false)
  };

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  useEffect(() => {
    //initial call of fetch message data
    fetchData(currentPage);
  }, [currentPage, limit, sort, keyword]);

  //make coloums for message list table
  const columns = [
    {
      dataField: "avatar_name",
      text: "Avatar Name"
    },
    {
      dataField: "description",
      text: "Your Description"
    },
    {
      text: "Shared For Users",
      formatter: (cell, row) => {
        // Assuming the preview URL can be dynamically generated or it's a static path
        const previewUrl = `/message-preview/${row.id}`; // Adjust the URL according to your requirement

        return (
          <>

          </>
        );
      },
    },
    {
      text: "Test",
      formatter: (cell, row) => {
        // Assuming the preview URL can be dynamically generated or it's a static path
        const previewUrl = `/message-preview/${row.id}`; // Adjust the URL according to your requirement

        return (
          <>
            <div className="action_icon_wrapper">
              <span
                className="action_icon"
              >
                <img src="/images/preview_icon.svg" />
              </span>
            </div>
          </>
        );
      },
    },
    {
      text: "Personalization",
      formatter: (cell, row) => {
        // Assuming the preview URL can be dynamically generated or it's a static path
        const personalizationUrl = `/user/avatars-personalization?id=${row.id}`; // Adjust the URL according to your requirement

        return (
          <NavLink href={`${personalizationUrl}`}>
            <div className="action_icon_wrapper personalization">

              <span
                className="action_icon"
              >
                <img src="/images/Vector.svg" />
              </span>

            </div>
          </NavLink>

        );
      },
    },

    {
      text: "Edit",
      formatter: (cell, row) => {
        return (
          <div className="action_icon_wrapper">
            <span
              className="action_icon"
              onClick={() => editAvatar(row)
              }
            >
              <img src="/images/edit_icon.svg" />
            </span>
          </div>

        );
      },
    },
    {

      text: "Delete",
      formatter: (cell, row) => {
        return (
          <div className="action_icon_wrapper">
            <span
              className="action_icon"
              onClick={() => {
                setSelectedDeleteMsg(row?.id);
                setShowDt(true);
              }}
            >
              <img src="/images/delete_icon.svg" />
            </span>
          </div>
        );
      },
    },
  ];


  //show success and fail message
  const showToast = (message, type) => {
    toast(message, {
      position: "top-right",
      autoClose: 2000,
      type,
      hideProgressBar: false,
      closeOnClick: true,
    });
  };

  return (
    <AuthLayout>
      <div className="content-inner messaging">
        <div className="overflow-content">
          <div className="customizer_wrapper">
            <Nav tabs>
              <NavItem>
                <NavLink href="/user/message?tab=1"
                  className={activeTab === "1" ? "active" : ""}
                  onClick={() => toggle("1")}
                >
                  <div className="customizer_link bg-black-theme" href="#">
                    <img src="/images/sms_icon.svg" className="customizer_card_icon" />
                    <h4 className="card_heading font_16 mb-2 font_700">SMS</h4>
                    <p className="card_para font_12">Send text message to phone without media</p>
                  </div>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="/user/message?tab=2"
                  className={activeTab === "2" ? "active" : ""}
                  onClick={() => toggle("2")}
                >
                  <div className="customizer_link bg-black-theme" href="#">
                    <img src="/images/MMS_icon.svg" className="customizer_card_icon" />
                    <h4 className="card_heading font_16 mb-2 font_700">MMS</h4>
                    <p className="card_para font_12">Send text message to phone with media: images, ringtones, videos</p>
                  </div>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="/user/message?tab=3"
                  className={activeTab === "3" ? "active" : ""}
                  onClick={() => toggle("3")}
                >
                  <div className="customizer_link bg-black-theme" href="#">
                    <img src="/images/MMS_icon.svg" className="customizer_card_icon" />
                    <h4 className="card_heading font_16 mb-2 font_700">E-Mail</h4>
                    <p className="card_para font_12">Send email with media: images, ringtones, videos</p>
                  </div>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="/user/message?tab=4"
                  className={activeTab === "4" ? "active" : ""}
                  onClick={() => toggle("4")}
                >
                  <div className="customizer_link bg-black-theme" href="#">
                    <img src="/images/MMS_icon.svg" className="customizer_card_icon" />
                    <h4 className="card_heading font_16 mb-2 font_700">Paper Letter</h4>
                    <p className="card_para font_12">Write a message, add an image, and we will print and deliver it!</p>
                  </div>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="/user/message?tab=5"
                  className={activeTab === "5" ? "active" : ""}
                  onClick={() => toggle("5")}
                >
                  <div className="customizer_link bg-black-theme" href="#">
                    <img src="/images/MMS_icon.svg" className="customizer_card_icon" />
                    <h4 className="card_heading font_16 mb-2 font_700">Add Item In Storage</h4>
                    <p className="card_para font_12">Write a message, add an image, and we will print and deliver it!</p>
                  </div>
                </NavLink>
              </NavItem>
            </Nav>
            <div className="padding_pg">
              <CardHeader className="border-0 bg-white-theme p-0 mb-4">
                <h3 className="mb-0 card-title font_18">
                  List Of Your Avatars
                </h3>
              </CardHeader>
              <Row>
                <Col sm="12" md="12" xl="12" className="col-xxl-12">
                  <Form role="form" onSubmit={handleSubmit}>
                    <ul className="ps-3">
                      <li className="bg-white-theme">
                        <h3 className="delivery_heading font_18 font_700 mb-1">Add A New AI Avatar</h3>
                        <div className="d-flex align-items-start gap-3 mb-3">
                          <FormGroup className="mb-3 col-md-3">

                            <label htmlFor="name" className="form-label font_black_12 font_700">
                              Avatar Name
                            </label>

                            <Input
                              className="form-control form_input font_black_12"
                              id="avatarName"
                              name="avatarName"
                              placeholder=" Avatar Name"
                              type="text"
                              value={avatarName}
                              onChange={(e) => setAvatarName(e.target.value)}
                              maxLength={50}
                            />
                            {errors.avatarName && (
                              <div className="errorVal ">
                                {errors.avatarName}
                              </div>
                            )}

                          </FormGroup>

                          <FormGroup className="mb-3 col-md-3">
                            <label htmlFor="name" className="form-label font_black_12 font_500">
                              Description
                            </label>
                            <div className="d-flex gap-2 position-relative ">
                              <Input
                                className="form-control form_input font_black_12 h-auto"
                                id="description"
                                name="description"
                                placeholder="Description"
                                type="textarea"
                                value={description}
                                maxLength="500"
                                onChange={(e) => setDescription(e.target.value)}
                              />

                              <span class="textarea_count">{description?.length}/500</span>
                            </div>
                            {errors.description && (
                              <div className="errorVal mt-2">
                                {errors.description}
                              </div>
                            )}

                          </FormGroup>
                          <FormGroup className="mb-0  col-md-3 template_buttons">
                            <Button className="btn smoke_btn font_700" type="button" onClick={cancel}>
                              Cancel
                            </Button>

                            {load ? (
                              <Button className="btn smoke_btn  font_700" disabled>
                                Processing...
                              </Button>
                            ) : (
                              <Button className="btn navy_btn font_700" type="submit">
                                {avatarId ? "Update Avatar" : "Add Avatar"}
                              </Button>
                            )}

                          </FormGroup>
                        </div>
                      </li>

                    </ul>

                  </Form>
                </Col>
              </Row>

              <CardHeader className="border-0 bg-white-theme p-0">
                <div className="d-flex gap-1 mb-3">
                  <div className="select-filter-wrapper w-25 ms-auto search-wrapper h-38 mb-0">
                    <i class="fa-solid fa-magnifying-glass"></i>
                    <Input
                      className="search_message h-38 font_12"
                      placeholder="Search by avatar name"
                      value={keyword}
                      type="text"
                      onChange={(e) => setKeyword(e.target.value)}
                    />
                  </div>
                  <div className="message-filter-wrapper select-filter-wrapper flex-nowrap">
                    <p className="font_12 w-nowrap">Short by : </p>
                    <div className="position-relative d-flex gap-3 pe-4 newest_filter">

                      <Select
                        class="form-select font_12"
                        classNamePrefix="react-select"
                        value={sort ? sortOptions.find(option => option.value === sort) : null}  // Find the selected option based on the state
                        onChange={(selectedOption) => setSort(selectedOption ? selectedOption.value : null)}  // Update the state with selected value
                        options={sortOptions}
                        placeholder="Select Sort Order"  // Optional: add a placeholder
                      />
                      <i class="fa-solid fa-angle-down"></i>
                    </div>
                  </div>
                </div>
              </CardHeader>
              {loading == false ? (
                <PaginatedTable
                  data={data1}
                  columns={columns}
                  totalPages={totalCount}
                  currentPage={currentPage}
                  onPageChange={setCurrentPage}
                  limit={limit}
                  setLimit={setLimit}
                  totalRecords={count}
                />
              ) : (
                <h2 className="text-center mb-4">Loading...</h2>
              )}
            </div>
          </div>
        </div>
      </div >

      <DeleteModal
        showdt={showDt}
        handleCloseDt={() => setShowDt(false)}
        handledelete={handleDelete}
      />
      <ToastContainer />

    </AuthLayout >
  );
};

export default AIAvatars;
